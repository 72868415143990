@import "./vars.scss";

.mitmachen {
    padding-top: 0;

    .header {
        width: 100%;
        padding: 30px 0;
        padding-top: 140px;
        background-color: $accentLight;

        .section-title {
            font-size: 60px;
            font-weight: bold;
            color: $accent;
            margin-bottom: 10px;
            line-height: 70px;

            @media (max-width: $mobileBP) {
                font-size: 40px;
                line-height: 50px;
            }
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;

            @media (max-width: $mobileBP) {
                flex-direction: column;
                gap: 10px;
            }
        }

        .discription {
            margin-bottom: 30px;
        }

        .cta-mitmachen {
            background-color: $accent;
            border-radius: 10px;
            text-decoration: none;
            padding: 20px;
            color: white;
            font-weight: bold;
            margin-bottom: 10px;
            transition: 0.2s;
            
            &:hover {
                opacity: 0.8;
            }
        }

        @media (max-width: $mobileBP) {
            padding-top: 120px;
            img {
                display: none;
            }
        }
    }
}
