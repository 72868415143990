@import "./vars.scss";

.projektbeschreibung {

    padding-top: 0;

    .header {
        width: 100%;
        padding: 30px 0;
        padding-top: 140px;
        background-color: $accentLight;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;

            @media (max-width: $mobileBP) {
                flex-direction: column;
                gap: 10px;
            }
        }

        .agenda {
            padding: 20px 0;;

            .entry {
                padding: 10px 20px;
            }
        }
    }
}