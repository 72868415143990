@import "./vars.scss";

.about {
    padding-top: 0;

    .header {
        width: 100%;
        padding: 30px 0;
        padding-top: 140px;
        background-color: $accentLight;

        .section-title {
            font-size: 60px;
            font-weight: bold;
            color: $accent;
            margin-bottom: 10px;
            line-height: 70px;

            @media (max-width: $mobileBP) {
                font-size: 40px;
                line-height: 50px;
            }
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;

            @media (max-width: $mobileBP) {
                flex-direction: column;
                gap: 10px;
            }
        }

        @media (max-width: $mobileBP) {
            padding-top: 120px;
            img {
                display: none;
            }
        }
    }

    .stats {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;

        .goLocal {
            width: 60%;
            padding-right: 30px;
            border-right: 1px solid black;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 30px;

            .icon {
                height: 100px;
                width: 100px;
            }

            @media (max-width: $mobileBP) {
                width: 100%;
                padding-right: 0;
                border-right: none;
                flex-direction: column;
                gap: 30px;
                text-align: center;
            }
        }

        .numbers {
            display: flex;
            justify-content: space-between;
            width: 28%;

            .number {
                display: flex;
                flex-direction: column;
                position: relative;

                .bg {
                    position: absolute;
                    top: -0px;
                    left: -20px;
                    background-color: red;
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    z-index: -1;

                    &.green {
                        background-color: #d1e3a4;
                    }

                    &.orange {
                        background-color: #ed8f6a;
                    }
                }

                .text {
                    font-size: 50px;
                    font-weight: bold;
                    color: $accent;
                }
            }

            @media (max-width: $mobileBP) {
                width: 100%;
                justify-content: space-evenly;
            }
        }

        @media (max-width: $mobileBP) {
            flex-direction: column-reverse;
            gap: 50px;
            margin-top: 50px;
        }
    }

    .organisation {
        margin-top: 100px;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 35px;
            font-weight: bold;
            color: $accent;
            margin-bottom: 20px;
        }

        .organisatoren {
            width: 55%;

            .personen {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                width: 100%;
                gap: 20px;

                .bg {
                    position: absolute;
                    z-index: -5;
                    height: 100%;
                    width: 100%;
                }

                .col {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    gap: 30px;
                }

                .person {
                    width: 100%;
                    padding-top: 10px;
                    background-color: #ebfaff;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .pb {
                        width: calc(100% - 20px);
                        height: calc(100% - 20px);
                        border-radius: 10px;
                        margin-bottom: 10px;
                    }

                    .name {
                        width: 100%;
                        font-size: 15px;
                        line-height: 15px;
                        text-align: center;
                        background-color: #d3e2eb;
                        padding: 5px 0;
                        border-radius: 0 0 10px 10px;
                    }

             
                }
            }

            @media (max-width: $mobileBP) {
                width: 100%;
            }
        }

        .zielgruppe {
            width: 40%;
        }

        @media (max-width: $mobileBP) {
            flex-direction: column;
            gap: 50px;
            margin-top: 50px;

            .zielgruppe {
                width: 100%;
            }
        }
    }
}
