@import "./vars.scss";

.blogArticle {
    .headerImage {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 5px;

        @media (max-width: $mobileBP) {
            height: 180px;
        }
    }

    .headerImageAlt {
        color: gray;
        font-size: 13px;
        margin-bottom: 20px;
        text-align: right;
    }

    .articleTitle {
        font-size: 35px;
        font-weight: bold;
        color: $accent;
        margin-bottom: 5px;
    }

    .content {
        display: flex;
        flex-direction: column;

        p {
            text-align: justify;
        }

        section {
            padding: 30px 0;

            &:nth-child(even) {
                background-color: $accentLight;
            }

            .sectionTitle {
                font-size: 30px;
                font-weight: bold;
                color: $accent;
                margin-bottom: 20px;
            }

            .imageSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 50px;

                img {
                    border-radius: 10px;
                    max-width: 40%;
                    max-height: 350px;
                }

                @media (max-width: $mobileBP) {
                    flex-direction: column;
                    gap: 30px;

                    img {
                        max-width: 100%;
                        max-height: 300px;
                    }
                }
            }

            .multiImageSection {
                display: flex;
                flex-direction: column;
                gap: 50px;

                .images {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 25px;

                    img {
                        border-radius: 10px;
                        max-width: 30%;
                        object-fit: cover;
                        height: 300px;
                    }

                    @media (max-width: $mobileBP) {
                        flex-direction: column;
                        gap: 10px;

                        img {
                            max-width: 100%;
                            width: 100%;
                            max-height: 300px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $mobileBP) {
        padding-top: 100px;
    }
}
