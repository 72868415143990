@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./vars.scss";

* {
    margin: 0;
    padding: 0;
}

*::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;

    @media (max-width: $mobileBP) {
        padding: 0 10px;
        max-width: calc(100% - 20px);
    }
}

.page {
    width: 100%;
    padding-top: 140px;

    @media (max-width: $mobileBP) {
        padding-top: 70px;
    }
}

a {
    color: $accent;
}

nav.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    padding-top: 30px;
    transition: 0.2s;

    .container {
        display: flex;
        justify-content: space-between;

        @media (max-width: $tabletBP) {
            flex-direction: column;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
            width: 80px;
            height: 80px;
            margin-right: 20px;
        }

        h1 {
            font-size: 35px;
            font-weight: bold;
            color: #ffffff;
            transition: 0.2s;

            .colored {
                color: #dde4ff;
            }
        }

        @media (max-width: $mobileBP) {
            h1 {
                font-size: 25px;
            }

            img {
                width: 60px;
                height: 60px;
                margin-right: 10px;
            }
        }
    }

    .static {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $tabletBP) {
            width: 100%;
        }
    }

    ul.links {
        display: flex;
        align-items: center;
        list-style: none;
        margin-left: 20px;
        transition: 0.2s;

        li a {
            margin-right: 20px;
            color: #22355b;
            text-decoration: none;
            font-size: 16px;
            font-weight: bold;
            transition: 0.5s;
            padding: 20px 0;

            &.active {
                font-style: oblique;
                color: $orange;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        @media (max-width: $tabletBP) {
            display: flex;
            overflow: hidden;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
            padding: 0px;
            height: 0;
            opacity: 0;

            li {
                border-bottom: 0.5px solid gray;
                width: 100%;
                display: flex;

                a {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    text-align: center;
                }

                &:last-child {
                    border-bottom: none;
                }

                &:first-child a {
                    padding-top: 10px;
                }

                a:hover {
                    text-decoration: none;
                }
            }

            &.expanded {
                padding: 20px 0;
                height: auto;
                opacity: 1;
            }
        }
    }

    .burgerMenu {
        all: unset;
        display: none;
        transition: 0.2s;

        img {
            width: 30px;
            height: 30px;
        }

        @media (max-width: $tabletBP) {
            display: block;
            cursor: pointer;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    &.scrolled {
        background-color: white;
        padding: 10px 0 5px 0;
        box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.25);

        .logo {
            h1 {
                color: rgb(107, 107, 107);
            }

            .colored {
                color: #22355b;
            }
        }
    }
}

footer {
    background-color: #22355b;

    .container {
        width: 100%;

        color: white;
        padding: 40px 0;

        a {
            color: rgb(255, 255, 255);
            text-decoration: none;
            font-weight: bold;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }

        .cols {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .col {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &.images {
                    gap: 30px;

                    img {
                        max-width: 300px;
                        transition: 0.5s;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }

                    @media (max-width: $tabletBP) {
                        margin-top: 40px;

                        img {
                            max-width: 250px;
                        }
                    }
                }

                @media (max-width: $tabletBP) {
                    text-align: center;
                }
            }

            @media (max-width: $tabletBP) {
                flex-direction: column;
                align-items: center;

                .basicLinks {
                    display: none;
                }
            }
        }

        .copyright {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
