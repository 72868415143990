@import "./vars.scss";

.blog {
    padding-top: 0;

    .header {
        width: 100%;
        padding: 30px 0;
        padding-top: 140px;
        background-color: $accentLight;

        .section-title {
            font-size: 60px;
            font-weight: bold;
            color: $accent;
            margin-bottom: 10px;
            line-height: 70px;

            @media (max-width: $mobileBP) {
                font-size: 40px;
                line-height: 50px;
            }
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;

            @media (max-width: $mobileBP) {
                flex-direction: column;
                gap: 10px;
            }
        }

        @media (max-width: $mobileBP) {
            padding-top: 120px;
            img {
                display: none;
            }
        }
    }

    .articles {
        margin-top: 60px;
        display: flex;
        gap: 0px;
        flex-wrap: wrap;

        .articlePreview {
            flex-grow: 1;
            min-width: 300px;
            border-radius: 10px;
            margin-bottom: 20px;
            padding: 10px;
            transition: 0.2s;
            text-decoration: none;

            @media (min-width: $tabletBP) {
                max-width: calc(100%/3 - 20px);
            }

            .meta {
                .date {
                    font-size: 13px;
                    color: gray;
                    margin-bottom: 0px;
                }

                .title {
                    font-size: 25px;
                    font-weight: bold;
                    color: $accent;
                    margin-bottom: 5px;
                }

                .description {
                    font-size: 15px;
                    color: gray;
                    margin-bottom: 0px;
                }
            }

            .headerImage {
                width: 100%;
                height: 180px;
                object-fit: cover;
                border-radius: 5px;
            }

            &:hover {
                transform: scale(1.02);
                background-color: rgba(187, 187, 187, 0.15)
            }
        }
    }
}
