@import "./vars.scss";

.landing {
    header {
        position: absolute;
        background: linear-gradient(103.19deg, #7390bb 7.64%, #cbdae1 89.04%);
        width: 100%;
        height: 500px;
        top: 0;
        left: 0;
        z-index: -1;
        padding-top: 200px;
        overflow: hidden;

        h1 {
            font-size: 55px;
            font-weight: 900;
            color: #fff;
            opacity: 0.8;
            line-height: 70px;

            @media (max-width: $mobileBP) {
                font-size: 40px;
                line-height: 50px;
            }
        }

        .teich {
            position: absolute;
            top: 0;
            left: calc(50% + 500px);
            object-fit: contain;
            z-index: 2;

            @media (max-width: $mobileBP) {
                display: none;
            }
        }

        .stuff {
            position: absolute;
            top: 130px;
            left: calc(50% + 0px);
            max-height: 708px;
            z-index: 10;

            @media (max-width: $mobileBP) {
                top: 321.5px;
                left: calc(10%);
                max-height: 408px;
            }
        }

        .headerStats {
            margin-top: 50px;
            display: flex;
            gap: 10px;

            .stat {
                background-color: rgba(255, 255, 255, 0.3);
                padding: 20px;
                max-width: 200px;
                border-radius: 2px;

                .big {
                    font-size: 70px;
                    font-weight: 900;
                    color: $orange;
                    line-height: 70px;

                    .borderText {
                        color: white;
                        text-shadow: -0.5px -0.5px 0 $orange, 0.5px -0.5px 0 $orange, -0.5px 0.5px 0 $orange,
                            0.5px 0.5px 0 $orange;
                    }
                }

                .title {
                    font-size: 20px;
                    font-weight: bold;
                    color: $accent;
                    line-height: 30px;
                }

                .subtitle {
                    font-size: 15px;
                    font-weight: bold;
                    color: gray;
                    line-height: 30px;
                }

                .seperator {
                    height: 0px;
                    border-top: 2px dashed rgba(128, 128, 128, 0.464);
                    margin: 10px 0;
                }

                .description {
                    font-size: 15px;
                    color: gray;
                }
            }

            .stat:first-of-type {
                background-color: white;
            }

            @media (max-width: $mobileBP) {
                display: none;
            }
        }

        .edge {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            border-left: 0px solid transparent;
            border-right: 80px solid transparent;
            border-bottom: 80px solid white;

            @media (max-width: $mobileBP) {
                border-right: 40px solid transparent;
                border-bottom: 40px solid white;
            }
        }

        @media (max-width: $mobileBP) {
            padding-top: 150px;
        }
    }

    .info-section {
        margin-top: 780px;

        .section-content {
            display: flex;
            gap: 50px;

            @media (max-width: $mobileBP) {
                flex-direction: column;
                gap: 10px;
            }
        }

        .info {
            max-width: 47%;

            .section-title {
                font-size: 60px;
                font-weight: bold;
                color: $accent;
                margin-bottom: 10px;
                line-height: 70px;

                .colored {
                    color: $yellow;
                }

                @media (max-width: $mobileBP) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }

            .subtitle {
                font-size: 30px;
                margin-bottom: 20px;
            }

            .discription {
                font-size: 20px;
                line-height: 1.5;
                color: #8f9aad;
                margin-bottom: 20px;

                @media (max-width: $mobileBP) {
                    font-size: 15px;
                }
            }

            @media (max-width: $mobileBP) {
                max-width: 100%;
            }
        }

        @media (max-width: $mobileBP) {
            margin-top: 680px;
        }
    }

    .waterTransition {
        width: 100%;
        margin-top: -20px;
        overflow: hidden;

        .graphic {
            width: 102%;
            margin-left: -5px;
        }

        @media (max-width: $tabletBP) {
            margin-top: 0px;
        }

        @media (max-width: $mobileBP) {
            margin-top: 50px;
        }
    }

    .ourArea {
        margin-top: -50px;
        background-color: #20375b;
        background: linear-gradient(to bottom, $accent 0%, #20375b 100%);

        .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .info {
                margin-top: 30px;
                max-width: 50%;

                .title {
                    font-size: 45px;
                    font-weight: bold;
                    color: white;
                    margin-bottom: 30px;
                    line-height: 50px;

                    @media (max-width: $mobileBP) {
                        font-size: 30px;
                        line-height: 20px;
                        margin-bottom: 10px;
                        margin-top: 20px;
                    }
                }

                .text {
                    font-size: 18px;
                    line-height: 1.5;
                    color: #8f9aad;
                    margin-bottom: 20px;

                    @media (max-width: $mobileBP) {
                        font-size: 15px;
                        text-align: center;
                    }
                }

                .mobileMap {
                    display: none;
                    width: 100%;
                    max-width: 300px;
                    margin: auto;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        object-fit: contain;
                    }

                    @media (max-width: $mobileBP) {
                        display: block;
                    }
                }

                @media (max-width: $mobileBP) {
                    max-width: 100%;
                }
            }

            .map {
                max-width: 45%;

                img {
                    width: 100%;
                    object-fit: contain;
                }

                @media (max-width: $mobileBP) {
                    display: none;
                }
            }
        }

        @media (max-width: $mobileBP) {
            margin-top: -30px;
        }
    }

    .imageShow {
        width: 100%;
        margin-top: 0px;
        margin-bottom: -10px;
        overflow: hidden;

        .graphic {
            width: 102%;
            margin-left: -5px;
        }

        @media (max-width: $tabletBP) {
            margin-top: 0px;
        }

        @media (max-width: $mobileBP) {
            margin-top: 0px;
        }
    }
}
