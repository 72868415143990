@import "./vars.scss";

.pingHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .ping {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid $orange;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $orange;
            margin: auto;
        }
    }

    p {
        color: $orange;
    }
}