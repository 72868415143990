@import "./vars.scss";

.agenda {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    max-width: 50%;
    background: rgb(237, 241, 250);
    background: radial-gradient(circle, rgba(240, 240, 250, 1) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 50px;
    padding: 40px 0;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.07);
    margin-left: 50px;

    .entry {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.1);
        transform: translateX(-50px);
        transition: 0.5s;

        .bar {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .sec {
            display: flex;
            align-items: center;
        }

        .icon {
            min-width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #facf42;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .graphic {
                max-width: 50px;
                max-height: 50px;
            }

            @media (max-width: $mobileBP) {
                max-width: 40px;
                min-height: 40px;

                .graphic {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .title {
            font-size: 20px;
            font-weight: bold;
            color: #22355b;
            margin-right: 20px;

            @media (max-width: $mobileBP) {
                font-size: 16px;
            }
        }

        .date {
            font-size: 20px;
            line-height: 1.5;
            color: #8f9aad;
            border-left: 0.5px solid #8f9aad;
            padding-left: 20px;

            @media (max-width: $mobileBP) {
                font-size: 16px;
                padding-left: 10px;
            }
        }

        .description {
            font-size: 15px;
            line-height: 1.5;
            color: #8f9aad;
            height: 0;
            overflow: hidden;
            transition: 0.5s;
            margin-top: 0;
            opacity: 0;
            font-size: 15px;

            &.expanded {
                margin: 10px;
                margin-top: 15px;
                height: auto;
                min-height: 140px;
                opacity: 1;
            }
        }

        &:hover {
            box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.25);
            transform: translateX(-40px);
        }

        @media (max-width: $mobileBP) {
            padding: 10px;
            transform: translateX(-10px);

            &:hover {
                transform: translateX(-10px);
            }
        }
    }

    @media (max-width: $mobileBP) {
        margin-left: 10px;
        border-radius: 10px;
        padding: 20px 0;
        max-width: 100%;
    }
}