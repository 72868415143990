.law {
    h1 {
        margin-bottom: 10px;
        font-size: 40px;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 30px;
    }

    h3 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 25px;
    }

    h4 {
        margin-top: 25px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    ul {
        padding-left: 20px;
        list-style: disc;
    }
}