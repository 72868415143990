@import "./vars.scss";

.process {
    padding: 50px 0 70px 0;
    &.hilighted {
        background-color: $accentLight;
    }

    .title {
        font-size: 50px;
        font-weight: bold;
        color: $accent;
        margin-bottom: 30px;
        text-align: center;

        @media (max-width: $mobileBP) {
            font-size: 40px;
        }
    }

    .tasks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .task {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .index {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: $accent;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                font-weight: bold;
                font-size: 30px;
            }

            .title {
                font-size: 20px;
                margin-bottom: 10px;
            }

            .descrition {
                font-size: 14px;
                text-align: center;
            }

            .seperator {
                display: none;
                width: 40%;
                height: 3px;
                border-bottom: 3px dashed $accent;
                position: absolute;
                top: 10%;
                right: -20%;
            }

            @media (max-width: $mobileBP) {
                width: 100%;
                margin-bottom: 20px;
                margin-top: 20px;
            }
        }
    }
}
